@charset "utf-8";

@import "nuclear";

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    z-index: -1;
}

@font-face {
    font-family: 'microgramma-bold';
    src: url('/fonts/microgramma-bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

h1,h2,h3,legend, .mg-bold,.menu-label, .title {
    font-family: "microgramma-bold", system-ui, sans-serif !important;
    letter-spacing: 0.05em;
}

html, body {
	height: 100%;
}

.is-full-height {
	height: 100%
}
